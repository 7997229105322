a {
  cursor: pointer;
}

html, body, #root, .wrapper {
  height: 100%;
  /* background: none !important; */
}

/* body {
  background: white !important;
} */

/* #root {
  padding: 2em !important;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  /* padding: 2em !important; */
}

.pageContent {
  flex: 1 0 auto;
  margin: 2em !important;
  padding: 0 !important;
}

.pageContent.mobile {
  margin: 5px !important;
}

.mobile .container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

thead, thead th {
  background-color: #9CCEFC !important;
}

/* set hover selection for table row */
.ui.striped.table.selectable>tr:hover,
.ui.striped.table.selectable tbody tr:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.ui.striped.table>tr:nth-child(2n),
.ui.striped.table tbody tr:nth-child(2n) {
  background-color: #E1F4FF;
}

.footer {
  flex-shrink: 0;
  padding: 5em 0em !important;
}

.hidden {
  display: none;
}
/* backgound color and gradient */
html {
  background-color: LightCyan;
  background-image: linear-gradient(#E1F4FF 0%, #9CCEFC 60%, #2185D0 75%);
  background-image: linear-gradient(141deg, #E1F4FF 0%, #9CCEFC 55%, #2185D0 75%);
  background-position: center;
  background-attachment: fixed;

  height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  background: none;
}